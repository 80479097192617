import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["modal", "content"];

  close() {
    this.modalTarget.classList.remove("modal--show");
  }

  async open(e) {
    const idEl = e.target.closest("[data-id]");
    const res = await get(`/audit_logs/${idEl.dataset.id}`);

    if (res.ok) {
      this.contentTarget.innerHTML = await res.html;
      this.modalTarget.classList.add("modal--show");
    }
  }
}
